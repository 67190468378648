export enum JointType
{
    Empty = 0,
    Head = 1,
    Neck = 2,
    Torso = 3,
    Waist = 4,
    LeftCollar = 5,
    LeftShoulder = 6,
    LeftElbow = 7,
    LeftWrist = 8,
    LeftHand = 9,
    LeftHandTip = 10,
    RightCollar = 11,
    RightShoulder = 12,
    RightElbow = 13,
    RightWrist = 14,
    RightHand = 15,
    RightHandTip = 16,
    LeftHip = 17,
    LeftKnee = 18,
    LeftAnkle = 19,
    LeftFoot = 20,
    RightHip = 21,
    RightKnee = 22,
    RightAnkle = 23,
    RightFoot = 24
}